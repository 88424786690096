import React from "react";
import { StyleSheet } from "react-native";
import ValeLaunchButton from "../../molecules/ValeLaunchButton";

const ClaimVibes = () => {

  return (
    <div style={styles.mainBox}>
        
        <center>
        <div style={{color:"white", fontFamily:"Inter"}}>
            <h1>claim vibes page is working</h1>
        </div>
        </center>

    </div>
  );
};

export default ClaimVibes;

const styles = StyleSheet.create({

  mainBox: {
    marginTop: "100px",
  },


});