// LocationSelector.tsx
import React, { useState } from 'react';
import { PageTitle } from '../../atoms/TextStyles';
import PromptModalPage from '../modal/PromptModalPage';
import ValeLaunchButton from '../../molecules/ValeLaunchButton';



const LocationSelector: React.FC = () => {

  function orderHere(loc: any) {

    //set location
    //update menu
    window.location.href = "/order/selection";
    console.log(loc);
  }

  return (
    <>
      <PromptModalPage onCloseButtonClicked={() => {}}>
        <PageTitle>SELECT LOCATION</PageTitle>

        <div style={{marginTop: '50px'}}>

          <div style={{
            width: '40%',
            maxWidth: '200px',
            backgroundColor:'white', 
            height:'260px', 
            borderRadius:'12px',
            border:'3px solid black',
            float:'left',
            marginLeft: '6.66%',
            marginRight: '3.33%'}}>

              <h1 style={{fontFamily:'BN Dime Display', fontWeight:'400', textAlign:'center', color:'#2c2c2c', fontSize: '26px'}}>CAFE</h1>

              <center>
                <img src='/images/icon_cafe.png' style={{width: '90%'}} />
                

                <ValeLaunchButton 
                  id='submitBtn'
                  width={165}
                  height={55}
                  fontStyle={{ fontSize: 18 }}
                  onPress={() => orderHere("cafe-9876")}>
                    ORDER HERE
                </ValeLaunchButton>
              
              </center>

          </div>


          <div style={{
            width: '40%', 
            backgroundColor:'white', 
            height:'260px', 
            borderRadius:'12px',
            border:'3px solid black',
            float:'left',
            }}>

              <h1 style={{fontFamily:'BN Dime Display', fontWeight:'400', textAlign:'center', color:'#2c2c2c', fontSize: '26px'}}>TRUCK</h1>

              <center>
                <img src='/images/icon_truck.png' style={{width: '90%'}} />
              

                <ValeLaunchButton 
                  id='submitBtn'
                  width={165}
                  height={55}
                  fontStyle={{ fontSize: 18 }}
                  onPress={() => orderHere("truck-12345")}>
                    ORDER HERE
                </ValeLaunchButton>

              </center>

          </div>

        </div>



      </PromptModalPage>
    </>
  );
};


export default LocationSelector;
